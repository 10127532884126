// This file will be for call global components like toasts for success and errors, or create new global functions
// like date generators
import store from "./store";
import moment from "moment";
import BalanceSmsService from "./services/BalanceSms.service";
import { PhoneNumberUtil } from 'google-libphonenumber'

//This function will calculate the balance one time
async function getBalance () {
    try {
      const response = await BalanceSmsService.getBalance()
      store.state.balance = response.data.balance
    } catch (error) {
      console.error('Fallo cargando el saldo')
    }
}

//Functions to show the toast for user
function getToast (message) {
    store.state.toast.display = true
    store.state.toast.message = message
}
function getToastError (message) {
    store.state.toast.display = true
    store.state.toast.error = true
    store.state.toast.message = message
}

//Function that format a date to a nice way
function getDateFormat (date) {
    return moment(date).locale('es').format('L')
}

//Function to get money as currency (this is possible to evolve adding currencies at the switch)
function transformToCurrency (total, currency) {
    let coin = ''
    switch (currency) {
        case 'eur':
            coin = '€'
    }
    return `${total.toFixed(2)}${coin}`
}

//Function to add an icon to the countryCode
function iconifyCountryCode (countryCode) {
    const countryCodes = [
        { code: '34', name: 'España', flag: '🇪🇸' },
        { code: '44', name: 'Reino Unido', flag: '🇬🇧' },
    ]

    const country = countryCodes.find(elem => elem.code === countryCode);
    if (country) {
        return country.flag
    } else {
        return null
    }
}

//Function to get validation rules for inputs
function getValidationRules ({ limit = 0, type = 'string' } = {}) {
    const accentRegex = new RegExp('[áéíóúÁÉÍÓÚ´]')
    const phoneRegex = /^[0-9\s]+$/
    const numericRegex = /^(?:[0-9\s]*,?)?[0-9\s]*$/
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const cifRegex = /^\d{8}[A-Z]$/
    const propDefinitionRegex = /^[a-zA-Z0-9-]+$/i
    const rules = {
        required: value => !! value || 'Este campo es obligatorio',
        noAccent: (value) => {
            const result = accentRegex.test(value)
            if (result) {
                return 'El texto no debe contener tildes'
            }
            return true
        },
        onlyNumber: (value) => {
            if (value !== undefined && value !== '' && value !== null) {
                const result = phoneRegex.test(value)
                if (!result) {
                    return 'No puede contener letras'
                }
                return true
            }
        },
        propDefinition: (value) => {
            if (value !== undefined) {
                const result = propDefinitionRegex.test(value)
                if (!result) {
                    return 'Solo puede contener letras, dígitos y guiones'
                }
                return true
            }
        },
        date: value => value ? value : new Date(),
        textLimit: value => value ? value.length <= limit || 'Superado el límite de caracteres' : true,
        propertyType: (value) => {
            if (numericRegex.test(value)) {
                return true
            } else if (type === 'string' && typeof value === 'string') {
                return true
            } else {
                return `El valor debe ser de tipo ${type}. Vigila el número de comas introducido`
            }
        },
        email: (value) => {
            if (emailRegex.test(value)) {
                return true
            } else {
                return 'Debe ser un email válido'
            }
        },
        cif: (value) => {
            if (cifRegex.test(value)) {
                return true
            } else {
                return 'Debe ser un CIF valido'
            }
        }
    }

    return rules
}

//Function that parse a phone before saving
function parsePhones (phone) {
    return phone.replace(/\s/g, '');
}

//Function to show the UX Modal for confirm critical changes
function displayConfirmationModal () {
    store.state.modal.display = true
}

//Function to ask for the stripe public key
function stripePublicKey () {
    return 'pk_test_oith8ZEFSWgTZpeQZ8ftKLTl00t4b1sYmb'
}

//Function to check if phone is valid
function checkPhoneNumber (value, countryCode = 'ES') {
    const phoneUtil = PhoneNumberUtil.getInstance()
    let numberProto = ''
    try {
        numberProto = phoneUtil.parse(value, countryCode)
    } catch (error) {
        return ''
    }
    const isValid = phoneUtil.isValidNumber(numberProto)
    return isValid ? true : ''
}

//Function that checks the size of a file input
function checkFileSize (file, maxSize) {
    if (file) {
        return file.size <= maxSize ? true : false
    }
}

export default {
    getBalance,
    getDateFormat,
    getToast,
    getToastError,
    getValidationRules,
    iconifyCountryCode,
    transformToCurrency,
    parsePhones,
    displayConfirmationModal,
    stripePublicKey,
    checkPhoneNumber,
    checkFileSize
}
export default [
   {
      id: 'dashboard',
      name: 'Estadísticas',
      to: '/dashboard',
      sublinks: []
   },
   {
      id: 'campaigns',
      name: 'Envíos',
      to: '',
      sublinks: [
         {
            name: 'Mis envíos',
            to: '/campaigns'
         },
         {
            name: 'Nuevo envío SMS',
            to: '/campaigns-new'
         }
      ]
   },
   {
      id: 'contacts',
      name: 'Contactos',
      to: '',
      sublinks: [
         {
            name: 'Mis contactos',
            to: '/contacts'
         },
         {
            name: 'Nuevo contacto',
            to: '/contacts-new'
         },
         {
            name: 'Mis grupos',
            to: '/list'
         },
         {
            name: 'Nuevo grupo',
            to: '/list-new'
         },
         {
            name: 'Mis campos personalizados',
            to: '/property-definitions'
         },
         {
            name: 'Nuevo campo personalizado',
            to: '/property-definitions-new'
         },
         {
            name: 'Importar contactos',
            to: '/contacts-import'
         }
      ]
   },
   {
      id: 'templates',
      name: 'Plantillas de texto',
      to: '',
      sublinks: [
         {
            name: 'Mis plantillas',
            to: '/templates'
         },
         {
            name: 'Nueva plantilla',
            to: '/templates-new'
         }
      ]
   },
   {
      id: 'autoclean',
      name: 'Autolimpieza',
      to: '/charts.html',
      sublinks: []
   },
   {
      id: 'account',
      name: 'Cuenta y facturación',
      to: '',
      sublinks: [
         {
            name: 'Mi cuenta',
            to: '/account'
         },
         {
            name: 'Facturas',
            to: '/account-invoices'
         },
         {
            name: 'Obtener saldo',
            to: '/account-order'
         }
      ]
   },
   {
      id: 'help',
      name: 'Ayuda',
      to: '/help',
      sublinks: []
   }
]
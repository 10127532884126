<template>
  <v-app-bar class="startpage-navbar" color="#1d2a3a">
    <v-row align="center" justify="space-evenly" class="mt-0">
      <v-col cols="4" md="4">
        <router-link to="/">
          <v-img v-if="isMobileSize" :src="require('../../assets/IconoSMS-Blanco.png')" width="40"/>
          <v-img v-else :src="require('../../assets/logo-smsempresa-white.png')" width="250"/>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" class="text-center">
        <v-app-bar-title>
          <router-link v-if="loggedIn" to="/dashboard">
            <v-btn color="white" variant="flat" rounded="lg">
              <span class="fw-bold">Accede a tu panel</span>
            </v-btn>
          </router-link>
          <router-link v-else to="/login">
            <v-btn color="white" variant="flat" rounded="lg">
              <span class="fw-bold">Inicio sesión</span>
            </v-btn>
          </router-link>
        </v-app-bar-title>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
  export default {
    computed: {
      isMobileSize () {
        return this.$vuetify.display.smAndDown
      },
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    }
  }
</script>

<style scoped>
.startpage-navbar {
  height: 75px;
}
</style>

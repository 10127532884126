<template>
  <div id="app">
    <v-app v-if="$route.meta.isStartpage">
      <div>
        <startpage-nav-bar/>
        <!--load content-->
        <router-view />
        <startpage-footer/>
      </div>
    </v-app>
    <v-app v-else-if="$route.meta.hideNavigation">
      <router-view/>
    </v-app>
    <div v-if="$route.meta.application">
      <div class="sb-nav-fixed">
        <TopNav/>
        <div id="layoutSidenav">
          <SideNav/>
          <div id="layoutSidenav_content" :key="$route.fullPath">
            <!--load content-->
            <app-breadcrumbs/>
            <router-view/>
            <AppFooter/>
          </div>
        </div>
      </div>
    </div>
    <app-modal/>
    <app-toast/>
  </div>

</template>

<script>

  import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import AppFooter from '@/components/AppFooter.vue'
  import StartpageNavBar from "@/components/startpage/StartpageNavBar.vue";
  import StartpageFooter from "@/components/startpage/startpageFooter.vue";
  import AppToast from "@/components/Toast/AppToast.vue";
  import AppModal from "./components/Modal/ConfirmationModal.vue";
  import AppBreadcrumbs from "@/components/Navigation/AppBreadcrumbs.vue";

  export default {
    components: {
      AppBreadcrumbs,
      AppToast,
      AppModal,
      StartpageFooter,
      StartpageNavBar,
      TopNav,
      SideNav,
      AppFooter
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
      currentUser() {
        return this.$store.state.auth.user;

      },
      showAdminBoard() {
        if (this.currentUser && this.currentUser['roles']) {
          return this.currentUser['roles'].includes('ROLE_ADMIN');
        }

        return false;
      },
      showModeratorBoard() {
        if (this.currentUser && this.currentUser['roles']) {
          return this.currentUser['roles'].includes('ROLE_MODERATOR');
        }
        return false;
      }
    },

  };
</script>

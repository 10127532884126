import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import "bootstrap";
import '../assets/styles/global.scss'
//import "bootstrap/dist/css/bootstrap.min.css";
import  FontAwesomeIcon from "./plugins/font-awesome";
import VueSweetalert2 from 'vue-sweetalert2';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import mixin from "@/globalMixin";
import errorParser from './lib/errorParser';
import 'sweetalert2/dist/sweetalert2.min.css';
import $ from "jquery";
import moment from "moment";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import stripeI18n from './lib/stripeI18n';
import { en } from 'vuetify/locale'
import i18nLoader from './i18n/i18nLoader'
const es = i18nLoader.es
import 'moment-timezone'
//stripe plugin
// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe('pk_test_oith8ZEFSWgTZpeQZ8ftKLTl00t4b1sYmb');

//end stripe

//sidebar WITH https://github.com/amirkian007/vue-awesome-sidebar
// import vueAwesomeSidebar from 'vue-awesome-sidebar'
// import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
//end sidebar

const customTheme = {
    dark: false,
    colors: {
        primary: '#293c51',
        success: '#24d5d8',
        info: '#569FF7'
    }
}
const vuetify = createVuetify({
    locale: {
        locale: 'es',
        fallback: 'en',
        messages: { es, en}
    },
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    components: {
        ...components,
        VTimePicker
    },
    directives,
})
const app = createApp(App).use(vuetify);
app.config.globalProperties.$mixin = mixin;
app.config.globalProperties.$stripeI18n = stripeI18n
app.config.globalProperties.$errorParser = errorParser;
app.use(VueSweetalert2);
app.use(router)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filters = {
    formatNumber(number,maxDecimals,isPrice) {

        var formatPrice= isPrice? number/100: number;
        var showLastZeros = false;

        if(number===null|| number==='') { return ''; }
        if(!$.isNumeric(number)) { return number; }

        var _number = parseFloat(formatPrice);

        //If we must not show last zeros and is "integer" valuetotalTax
        if(!showLastZeros && _number == parseInt(_number)){

            return  parseInt(formatPrice).toLocaleString('de-DE', {
                'style': 'decimal',
                'minimumFractionDigits': 0,
                'maximumFractionDigits': 0
            }) ;

        }else {

            return _number.toLocaleString('de-DE', {
                'style': 'decimal',
                'minimumFractionDigits': maxDecimals,
                'maximumFractionDigits': maxDecimals
            }) ;
        }
        // 'es-ES' when operating with the first unit does not mark unit separator thousand

    }
}

app.mount('#app')
<template>
  <div>
    <v-btn v-if="!isMobileSize" color="red" rounded prepend-icon="mdi-logout" @click="logOut">
      <span class="d-none d-sm-block">Cerrar sesión</span>
    </v-btn>
    <v-btn v-else variant="text" color="red" icon="mdi-logout" @click="logOut"/>
  </div>
</template>

<script>
export default {
  name: "LogoutButton",

  computed: {
    isMobileSize () {
      return this.$vuetify.display.smAndDown
    }
  },

  methods: {
    logOut () {
      this.$store.dispatch('auth/logout');
      this.$store.state.auth.status.loggedIn = false
      this.$router.push('/login');
    }
  }
}
</script>
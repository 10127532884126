import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";



import {
  faBars,
  faUser,
  faSearch ,
  faCircleQuestion,
  faChartBar,
  faChartColumn,
  faAngleDown ,
  faCommentDots,
  faAddressBook ,
  faFileAlt,
  faBroom,
  faHome,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faTable,
  faCheckCircle,
  faCoins,
  faPlus,
  faArrowDown,
  faEllipsisV,
  faFileLines,
  faTriangleExclamation,
  faClock

} from "@fortawesome/free-solid-svg-icons";

import {
  //faCircleQuestion
} from '@fortawesome/free-regular-svg-icons';


library.add(
    faBars,
    faUser,
    faSearch ,
    faChartBar,
    faChartColumn,
    faAngleDown ,
    faCommentDots,
    faAddressBook ,
    faFileAlt,
    faBroom,
    faCircleQuestion,
    faHome,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faTable,
    faCheckCircle,
    faCoins,
    faPlus,
    faArrowDown,
    faEllipsisV,
    faFileLines,
    faTriangleExclamation,
    faClock


);

export default FontAwesomeIcon;

export default {
   parseError (error, errorField) {
      switch (error) {
         case `Given name to Contact List is already in use "${errorField}"`:
            return 'Ya existe un grupo con este nombre'
         case `Given Email is already in use "${errorField}"`:
            return 'Ya existe una cuenta con este email'
         case `Given Phone is already in use "${errorField}"`:
            return 'Ya existe una cuenta con este número de teléfono'
      }
   }
}
<template>
  <div class="login-init d-flex align-items-center justify-content-center">

    <div class="container-sm">
      <div class="row">
        <div class=" col-12">
          <div class="row shadow-lg init-div">
            <div class="col-12 col-md-8 init-promo-div  p-4 text-start ">

              <div class="row h-100">
                <div class="col-12 col-md-6 d-flex align-items-start flex-column">
                  <h1 class="logo-brand mb-auto">
                    <img src="../assets/logo-smsempresa-white.png" style="max-width:300px;height:auto;" alt="" title="" />
                  </h1>

                  <div class="text-white mt-4">

                    <h3 class="fw-light">Invierte en tu negocio</h3>
                    <p class="fw-light">Configuramos tu campaña de publicidad en Google en función de los objetivos que quieras alcanzar.</p>
                  </div>
                </div>
              </div>

            </div>

            <v-card class="col-12 col-md-4 init-login-div d-flex align-items-center justify-content-center" height="535">
              <v-card-text>
                <v-form ref="loginForm" @submit.prevent="getValidationHandle">
                  <h3 class="text-primary mb-4">{{ getFormTitle }}</h3>
                  <v-text-field
                    v-model="usernamePrompt"
                    :rules="usernameRules"
                    color="primary"
                    label="Email"
                    type="email"
                    variant="outlined"
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="status === 'token'"
                    v-model="tokenPrompt"
                    color="primary"
                    label="Token de acceso"
                    variant="outlined"
                  ></v-text-field>
                  <span v-if="status === 'token'" class="text-grey pb-2 my-2">Se ha enviado el token al número de telefono introducido. La contraseña de acceso será el mismo número de teléfono. Puede cambiarlo mas tarde</span>
                  <v-text-field
                    v-if="status === 'login'"
                    v-model="passwordPrompt"
                    :type="hidePassword ? 'password' : 'input'"
                    color="primary"
                    label="Contraseña"
                    variant="outlined"
                  >
                    <template v-slot:append-inner>
                      <div class="d-flex align-center">
                        <v-divider vertical></v-divider>
                      </div>
                      <v-icon :icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'" class="clickable" @click="hidePassword = !hidePassword"></v-icon>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-if="status === 'register'"
                    v-model="phonePrompt"
                    color="primary"
                    label="Número de teléfono"
                    variant="outlined"
                  >

                  </v-text-field>
                  <div class="d-flex justify-center">
                    <v-btn
                      v-if="status === 'login'"
                      class="mb-4"
                      color="primary"
                      size="x-small"
                      variant="text"
                      @click="resetPasswordStatus = true"
                    >
                      ¿Olvidaste tu contraseña?
                    </v-btn>
                  </div>
                  <v-btn
                    :loading="loading"
                    block
                    color="primary"
                    icon="mdi-arrow-right"
                    rounded="sm"
                    size="small"
                    type="submit"
                  >
                  </v-btn>
                </v-form>
                <v-btn
                      class="mt-6"
                      color="primary"
                      size="x-small"
                      variant="text"
                      @click="toggleForm"
                    >
                      {{ getToggleFormTitle  }}
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
        </div>

      </div>
    </div>

    <forgotten-password-modal :status="resetPasswordStatus" @update-status="updateResetPasswordStatus"></forgotten-password-modal>
  </div>
</template>


<script>
  import * as yup from "yup"
  import authService from '../services/auth.service'
  import userService from '../services/user.service'
  import ForgottenPasswordModal from "@/components/user-gestion/ForgottenPasswordModal.vue"

  export default {
    name: "AppLogin",
    components: {
      ForgottenPasswordModal
    },
    data() {
      const schema = yup.object().shape({
        username: yup.string().required("Username is required!"),
        password: yup.string().required("Password is required!"),
      })

      return {
        formInvalid: false,
        hidePassword: true,
        isNewRegister: false,
        loading: false,
        message: "",
        passwordPrompt: '',
        phonePrompt: '',
        resetPasswordStatus: false,
        schema,
        status: 'login',
        tokenPrompt: '',
        usernamePrompt: '',
        usernameRules: []
      }
    },
    computed: {
      getButtonStatus () {
        return ''
      },
      getFormTitle () {
        if (this.status === 'login' ) {
          return 'Iniciar sesión'
        }
        if (this.status === 'register') {
          return 'Registro'
        }
        if (this.status === 'token') {
          return 'Introduzca token'
        }
        return ''
      },
      getToggleFormTitle () {
        if (this.status === 'login') {
          return '¿No tienes cuenta? ¡Regístrate ya!'
        } 
        if (this.status === 'register') {
          return '¿Ya tienes cuenta? Inicia sesión'
        }
        return ''
      },
      loggedIn() {
        return this.$store.state.auth.status.loggedIn
      }
    },

    mounted () {
      if (this.$route.meta.register) {
        this.status = 'register'
      }
    },
    methods: {
      async getValidationHandle () {
        if (this.status === 'login') {
          this.handleLogin()
        }
        if (this.status === 'register') {
          this.usernameRules.push(this.$mixin.getValidationRules().email)
          const { valid } = await this.$refs.loginForm.validate()
          if (valid) {
            this.handleRegister()
          }
        }
        if (this.status === 'token') {
          this.handleToken()
        }
      },
      async handleLogin() {
        this.loading = true
        try {
          const user = {
          username: this.usernamePrompt,
          password: this.passwordPrompt
          }

          const res = await authService.login(user)
          if (res.data.token) {
            localStorage.setItem('user', JSON.stringify(res.data));
            this.$router.push('/dashboard')
          }
        } catch (error) {
          this.$mixin.getToastError('Error iniciando sesión. Compruebe usuario y contraseña')
        } finally {
          this.loading = false
        }
      },
      async handleRegister () {
        try {
          this.loading = true
          const user = {
            email: this.usernamePrompt,
            phone_number: this.phonePrompt
          }
          await authService.register(user)
          this.status = 'token'
        } catch (error) {
          this.$mixin.getToastError('Error en el registro')
        } finally {
          this.loading = false
        }
      },
      async handleToken () {
        try {
          const data = {
            token: this.tokenPrompt,
            email: this.usernamePrompt
          }

          const res = await userService.registerToken(data)
          if (res.data.token) {
            localStorage.setItem('user', JSON.stringify(res.data));
            setTimeout(() => {
            this.$router.push('/dashboard')
          }, 5000)
          }
        } catch (error) {
          this.$mixin.getToastError('Error validando el token')
        }
      },
      updateResetPasswordStatus (status) {
        this.resetPasswordStatus = status
      },
      toggleForm () {
        if (this.status === 'login') {
          this.status = 'register'
        } else if (this.status === 'register') {
          this.status = 'login'
        }
        this.usernamePrompt = ''
        this.passwordPrompt = ''
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import  '../../assets/styles/login.scss';

  input:autofill {
    background-color: white !important
  }
  
  input:-webkit-autofill {
    background-color: white !important
  }
</style>
<template>
  <nav class="sb-topnav navbar navbar-expand navbar-light bg-white border-bottom">
    <!-- Navbar Brand-->
    <router-link to="/dashboard" class="navbar-brand">
      <img class="img-fluid pe-5 ps-3" src="../assets/logo-smsempresa-color.png" alt="sms-empresa logo" >
    </router-link>
     <!-- Sidebar Toggle-->
    <button class="btn p-0  btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0 menu-link-wrapper" id="sidebarToggle" href="#!"  @click.prevent="toogleMenu">
     <!-- arrow btn to hamburger-->
      <div class="menu-link">
        <span class="lines"></span>
      </div>
    </button>


    <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
      <li class="me-0 me-sm-4"><get-cash-button/></li>
      <li><logout-button/></li>
    </ul>

  </nav>
</template>

<script>
import GetCashButton from "@/components/buttons/GetCashButton.vue";
import LogoutButton from "@/components/buttons/LogoutButton.vue";

export default {
  name: 'topNav',
  components: {LogoutButton, GetCashButton},
  props: {
    msg: String
  },
  methods: {
    toogleMenu(){

      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    }
  },
  created(){

        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
           document.body.classList.toggle('sb-sidenav-toggled');
        }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

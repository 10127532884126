import { createStore } from "vuex";
import { auth } from "./auth.module";
import { toast } from "../store/toast.module";
import { balance } from "../store/balance.module"
import { modal } from "../store/modal.module"

const store = createStore({

  modules: {
    auth,
    balance,
    modal,
    toast
  },
});

export default store;
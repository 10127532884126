
<template>

  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">Copyright &copy; SMS Empresa 2024</div>
        <div>
          <router-link :to="'/politica-privacidad'">Política de privacidad</router-link>
          &middot;
          <router-link :to="'/terminos-condiciones'">Términos &amp; Condiciones</router-link>
        </div>
      </div>
    </div>
  </footer>

</template>

import baseService from "@/services/baseService";

class BalanceSmsService {

    getBalance() {
     return baseService.get('my-account/license/balance/')
  }


}

export default new BalanceSmsService();

import axios from "axios";
import get from 'lodash/get'
import globalMixin from "@/globalMixin";
import router from "@/router";
import apiURLS from "@/apiURLS";
import authHeader from "./auth-header"

const baseService = axios.create({
    baseURL: apiURLS.API_URL,
    headers: authHeader()
})

baseService.interceptors.request.use(config => {
    config.headers = authHeader()
    return config
})
baseService.interceptors.response.use(
    function (response) {
        return response
    },

    function (error) {
        if (get(error, 'request.status') === 401) {
            globalMixin.getToastError('Token expirado, vuelva a Iniciar Sesión')
            router.push('login')
        } else {
            return Promise.reject(error)
        }
    }
)

export default baseService
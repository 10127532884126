<template>

  <!--<router-link to="/">Home</router-link> |-->
  <!--<router-link to="/about">About</router-link>-->

  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div class="sb-sidenav-menu  mt-4">
        <div class="nav">
<!--          <div class="sb-sidenav-menu-heading">Core</div>-->
          <router-link to="/dashboard" class="nav-link">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="chart-bar" /></div>
            <div class="sb-nav-link-text">Estadísticas</div>
          </router-link>
          <!--<div class="sb-sidenav-menu-heading">Interface</div>-->
          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseSMSSend" aria-expanded="false" aria-controls="collapseLayouts">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="comment-dots" /></div>
            <div class="sb-nav-link-text">Envíos</div>
            <div class="sb-sidenav-collapse-arrow"><font-awesome-icon icon="angle-down" /></div>
          </a>
          <div class="collapse" id="collapseSMSSend" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <router-link to="/campaigns" class="nav-link small">Mis envíos</router-link>
              <router-link to="/campaigns-new" class="nav-link small">Nuevo envío SMS</router-link>
            </nav>
          </div>
          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseContacts" aria-expanded="false" aria-controls="collapseContactss">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="address-book" /></div>
            <div class="sb-nav-link-text">Contactos</div>
            <div class="sb-sidenav-collapse-arrow"><font-awesome-icon icon="angle-down" /></div>
          </a>
          <div class="collapse" id="collapseContacts" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionContacts">
              <router-link to="/contacts" class="nav-link small">Mis contactos</router-link>
              <router-link to="/contacts-new" class="nav-link small">Nuevo contacto</router-link>
              <router-link to="/list" class="nav-link small">Mis grupos</router-link>
              <router-link to="/list-new" class="nav-link small">Nuevo grupo</router-link>
              <router-link to="/property-definitions" class="nav-link small">Mis campos personalizados</router-link>
              <router-link to="/property-definitions-new" class="nav-link small">Nuevo campo personalizado</router-link>
              <router-link to="/contacts-import" class="nav-link small">Importar contactos</router-link>
            </nav>
          </div>

          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTemplates" aria-expanded="false" aria-controls="collapseTemplates">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="file-lines" /></div>
            <div class="sb-nav-link-text">Plantillas de texto</div>
            <div class="sb-sidenav-collapse-arrow"><font-awesome-icon icon="angle-down" /></div>
          </a>
          <div class="collapse" id="collapseTemplates" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionTemplates">
              <router-link to="/templates" class="nav-link small">Mis Plantillas</router-link>
              <router-link to="/templates-new" class="nav-link small">Nueva Plantilla</router-link>

            </nav>
          </div>
          <!--<div class="sb-sidenav-menu-heading">Addons</div>-->
          <router-link to="/contacts-unreachable" class="nav-link" href="charts.html">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="broom" /></div>
            <div class="sb-nav-link-text">Autolimpieza</div>
          </router-link>

          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseAcount" aria-expanded="false" aria-controls="collapsePages">
            <div class="sb-nav-link-icon"><font-awesome-icon icon="user" /></div>
            <div class="sb-nav-link-text">Cuenta y Facturación</div>
            <div class="sb-sidenav-collapse-arrow"><font-awesome-icon icon="angle-down" /></div>
          </a>
          <div class="collapse" id="collapseAcount" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
              <router-link to="/account" class="nav-link small" >Mi cuenta</router-link>
              <router-link to="/account-invoices" class="nav-link small" >Facturas</router-link>
              <router-link to="/account-order" class="nav-link small" >Obtener saldo</router-link>
              <router-link to="/payment-methods" class="nav-link small" >Métodos de pago</router-link>
            </nav>
          </div>

          <router-link to="/help" class="nav-link">
            <div class="sb-nav-link-icon">
              <font-awesome-icon :icon="['fas', 'circle-question']" />
            </div>
            <div class="sb-nav-link-text">Ayuda</div>
          </router-link>
          <div class="nav-link">
            <div v-if="hasLoads" class="py-4 sb-nav-link-text sb-sidenav-contacts-import">
              <h6 class="mb-4">Importación de contactos</h6>
              <div class="d-flex align-center">
                <v-menu v-model="errorMenuStatus" location="end">
                  <template v-slot:activator="{ props }"> 
                    <v-btn v-bind="props" class="" color="red" icon="mdi-alert-circle-outline" variant="text"></v-btn>
                    {{ loads?.error }} fallidas
                  </template>
                  <v-card>
                    <v-card-text>
                      Las cargas fallidas se están revisando por nuestro equipo. Pronto se pondrán en contacto con usted
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div class="d-flex align-center">
                <v-menu v-model="pendingMenuStatus" location="end">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="" color="warning" icon="mdi-clock-outline" variant="text"></v-btn>
                    {{ loads?.pending }} pendientes
                  </template>
                  <v-card>
                    <v-card-text>Estas son las cargas que aun no se han completado</v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div class="d-flex align-center">
                <v-menu v-model="successMenuStatus" location="bottom" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="" color="success" icon="mdi-check-circle-outline" variant="text"></v-btn>
                    {{ loads?.success }} completadas
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <p>Cargas finalizadas: {{ loads.success }}</p>
                        <v-divider thickness="3"></v-divider>
                        <v-virtual-scroll
                          :height="200"
                          :items="Object.values(loadsDetailed.success)"
                        >
                          <template v-slot:default="{ item, index }">
                            <div>
                              <p>
                                {{ item }}
                                <v-btn 
                                  class="ms-2"
                                  :loading="deleteNotificationsLoading"
                                  color="warning"
                                  icon="mdi-bell-remove"
                                  size="50"
                                  variant="text"
                                  @click="deleteNotifications(Object.keys(loadsDetailed.success)[index])"
                                ></v-btn>
                              </p>
                              
                            </div>
                          </template>
                        </v-virtual-scroll>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div v-if="hasLoads" class="sb-bell-icon">
              <v-badge :content="totalLoads" color="success" max="99" offset-x="5" offset-y="-1" text-color="primary">
                <v-icon color="warning" icon="mdi-bell"></v-icon>
              </v-badge>
            </div>
          </div>
        </div>
      </div>
      <div class="sb-sidenav-footer">
        <div class="sb-nav-link-icon align-self-center"><font-awesome-icon icon="coins" /></div>
        <div class="sb-nav-link-text">
          <div class="text-xs">Saldo actual:</div>
          <span v-show="loading.balance" class="spinner-border spinner-border-sm"></span>
          <span v-show="!loading.balance">{{balance}} SMS</span>
        </div>
      </div>

    </nav>
  </div>

</template>

<script>
import ContactImporterService from '../services/ContactImporter.service';


export default {
  name: 'SideNav',
  props: {
    msg: String
  },
  data() {
    return {
      deleteNotificationsLoading: false,
      errorMenuStatus: false,
      isSideNavOpen: false,
      loading: {
        balance:true,
      },
      loads: {
        error: 0,
        pending: 0,
        success: 0
      },
      loadsDetailed: null,
      pendingMenuStatus: false,
      successMenuStatus: false,
      totalLoads: 0
    };
  },

  computed: {
    balance () {
        return this.$store.state.balance
    },
    hasLoads () {
      return (this.loads?.error + this.loads?.pending + this.loads?.success) > 0
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  watch: {
    errorMenuStatus () {
      this.fixSidebar()
    },
    pendingMenuStatus () {
      this.fixSidebar()
    },
    successMenuStatus () {
      this.fixSidebar()
    }
  },

  async mounted() {
    this.loading.balance = false
    await this.getLoads()
      if (this.hasLoads === true) {
        this.getLoadsDetailed()
      }
    let intervalId = setInterval(async () => {
      await this.getLoads()
      if (this.hasLoads === true) {
        this.getLoadsDetailed()
      } else {
        clearInterval(intervalId)
      }
    }, 15000)
  },

  methods: {
    async deleteNotifications (uuid) {
      try {
        this.deleteNotificationsLoading = true
        await ContactImporterService.deleteLoad(uuid)
        this.getLoads()
        this.getLoadsDetailed()
      } catch (error) {
        this.$mixin.getToastError('No se han podido eliminar los avisos')
      } finally {
        this.deleteNotificationsLoading = false
      }
    },
    fixSidebar () {
      if (document.body.classList.contains('sb-sidenav-toggled')) {
        document.body.classList.remove('sb-sidenav-toggled')
      }
    },
    async getLoads () {
      try {
        const res = await ContactImporterService.getImportCount()
        this.loads = res.data
      } catch (error) {
        this.$mixin.getToastError('Error obteniendo el total de importaciones')
      }
    },
    async getLoadsDetailed () {
      try {
        const res = await ContactImporterService.listAllLoads()
        this.loadsDetailed = res.data
        this.totalLoads = this.loads.error + this.loads.pending + this.loads.success
      } catch (error) {
        this.$mixin.getToastError('Error cargando la información de las cargas')
      }
    }
  }

}//export

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

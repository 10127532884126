import { createWebHistory, createRouter } from "vue-router";

import Login from "./views/Login.vue";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import globalMixin from "./globalMixin";

// lazy-loaded
const AppAccount = () => import("./views/AppAccount.vue")
const AccountInvoices = () => import("./views/AccountInvoices.vue")
const AccountOrder = () => import("./views/AccountOrder.vue")
const AppDashboard = () => import("./views/AppDasboard.vue")
const Campaigns = () => import("./views/AppCampaigns.vue")
const CampaignsCreator = () => import("./components/campaings/campaignsCreator.vue")
const CampaignSent = () => import("./views/CampaignSent.vue")
const CampaignScheduled = () => import("./views/CampaignScheduled.vue")
const SendingRequestUuid = () => import("./views/SendingRequestUuid.vue")
const AppContacts = () => import("./views/AppContacts.vue")
const ContactInterface = () => import("./views/contactInterface.vue")
const ContactsUnreachable = () => import("./views/ContactsUnreachable.vue")
const AppList = () => import("./views/AppList.vue")
const GroupCreator = () => import("./views/GroupCreator.vue")
const MessageTemplates = () => import("./views/MessageTemplates.vue")
const TemplatesManager = () => import("./views/TemplatesManager.vue")
const PropertyDefinitions = () => import("./views/PropertyDefinitions.vue")
const PropertyDefinitionsNew = () => import("./views/PropertyDefinitionsManager.vue")
const AppHelp = () => import("./views/AppHelp.vue")
const PaymentMethods = () => import("./components/paymentMethods.vue")
const StartpageIndex = () => import("./pages/startpage/StartpageIndex.vue")
const PrivacyPolicy = () => import("./pages/legalPages/privacyPolicy.vue")
const TermsConditions = () => import("./pages/legalPages/TermsConditions.vue")
const RefundPolicy = () => import("./pages/legalPages/refundPolicy.vue")
const resetToken = () => import("./pages/ResetToken.vue");
const RegisterToken = () => import("./pages/RegisterToken.vue");
const ErrorPage = () => import("./views/AppError.vue");
const csvImporter = () => import("./components/csvImporter.vue")

const routes = [

  {
    name: 'Inicio de sesión',
    path: "/login",
    component: Login,
    meta: { hideNavigation: true, authRequired: false }
  },
  {
    name: 'Registro',
    path: "/register",
    component: Login,
    meta: { hideNavigation: true, register: true, authRequired: false }
  },
  {
    name: 'Mi cuenta',
    path: "/account",
    component: AppAccount,
    meta: { application: true, breadcrumb: "account" }
  },
  {
    name: 'Facturas',
    path: "/account-invoices",
    component: AccountInvoices,
    meta: { application: true, breadcrumb: "account" }
  },
  {
    name: 'Obtener saldo',
    path: "/account-order",
    component: AccountOrder,
    meta: { application: true, breadcrumb: "account" }
  },
  {
    path: "/",
    name: "index",
    component: StartpageIndex,
    meta: { isStartpage: true }
  },
  {
    name: 'Dashboard',
    path: "/dashboard",
    component: AppDashboard,
    meta: { application: true }
  },

  {
    name: 'Mis envíos',
    path: "/campaigns",
    component: Campaigns,
    meta: { application: true, breadcrumb: "campaigns" }
  },


  {
    name: 'Nuevo envío SMS',
    path: "/campaigns-new",
    component: CampaignsCreator,
    meta: { application: true, editMode: false, breadcrumb: "campaigns" }
  },

  {
    name: 'Editar envío SMS',
    path: "/campaigns-edit",
    component: CampaignsCreator,
    meta: { application: true, editMode: true, breadcrumb: "campaigns" }
  },

  {
    name: 'SMS enviados',
    path: "/campaign-sent",
    component: CampaignSent,
    meta: { application: true, breadcrumb: "campaigns" }
  },

  {
    name: 'SMS programados',
    path: "/campaign-scheduled",
    component: CampaignScheduled,
    meta: { application: true, breadcrumb: "campaigns" }
  },

  {
    name: "Info de Mensaje",
    path: "/sendingRequest-uuid",
    component: SendingRequestUuid,
    meta: { application: true, breadcrumb: "campaigns" }
  },


  {
    name: 'Contactos',
    path: "/contacts",
    component: AppContacts,
    meta: { application: true, breadcrumb: "contacts" }
  },
  {
    name: 'Nuevo contacto',
    path: "/contacts-new",
    component: ContactInterface,
    meta: { application: true, breadcrumb: "contacts" }
  },
  {
    name: 'Editar contacto',
    path: "/contacts-edit",
    component: ContactInterface,
    meta: { application: true, editMode: true, breadcrumb: "contacts" }
  },
  {
    name: 'Autolimpieza',
    path: "/contacts-unreachable",
    component: ContactsUnreachable,
    meta: { application: true, breadcrumb: "contacts" }
  },

  {
    name: 'Importar contactos',
    path: "/contacts-import",
    component: csvImporter,
    meta: { application: true, breadcrumb: "contacts" }
  },

  {
    name: 'Grupos',
    path: "/list",
    component: AppList,
    meta: { application: true, breadcrumb: "contacts" }
  },
  {
    name: 'Nuevo grupo',
    path: "/list-new",
    component: GroupCreator,
    meta: { application: true, breadcrumb: "contacts" }
  },

  {
    name: 'Editar grupo',
    path: "/list-edit",
    component: GroupCreator,
    meta: { application: true, breadcrumb: "contacts", editMode: true }
  },

  {
    name: 'Campos personalizados',
    path: "/property-definitions",
    component: PropertyDefinitions,
    meta: { application: true, breadcrumb: "contacts" }
  },
  {
    name: 'Nuevo campo personalizado',
    path: "/property-definitions-new",
    component: PropertyDefinitionsNew,
    meta: { application: true, breadcrumb: "contacts" }
  },

  {
    name: 'Editar campo personalizado',
    path: "/property-definitions-edit",
    component: PropertyDefinitionsNew,
    meta: { application: true, breadcrumb: "contacts", editMode: true }
  },


  {
    name: 'Plantillas',
    path: "/templates",
    component: MessageTemplates,
    meta: { application: true, breadcrumb: "templates" }
  },

  {
    name: 'Nueva plantilla',
    path: "/templates-new",
    component: TemplatesManager,
    meta: { application: true, breadcrumb: "templates" }
  },

  {
    name: 'Editar plantilla',
    path: "/templates-edit",
    component: TemplatesManager,
    meta: { application: true, breadcrumb: "templates", editMode: true }
  },

  {
    name: 'Ayuda',
    path: "/help",
    component: AppHelp,
    meta: { application: true, breadcrumb: "help" }
  },
  {
    path: "/politica-privacidad",
    name: "privacy",
    meta: { isStartpage: true, authRequired: false },
    component: PrivacyPolicy
  },
  {
    path: "/terminos-condiciones",
    name: "terms",
    meta: { isStartpage: true, authRequired: false },
    component: TermsConditions
  },
  {
    path: "/politica-devoluciones",
    name: "refund",
    meta: { isStartpage: true, authRequired: false},
    component: RefundPolicy
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    meta: { hideNavigation: true, authRequired: faLessThanEqual },
    component: resetToken
  },
  {
    path: "/register-token",
    name: "register-token",
    meta: { hideNavigation: true, authRequired: false },
    component: RegisterToken
  },
  {
    path: "/error",
    name: "error",
    meta: { hideNavigation: true },
    component: ErrorPage
  },
  {
    path: "/payment-methods",
    name: "Métodos de pago",
    meta: { application: true, authRequired: true, breadcrumb: 'account', fromRoute: true },
    component: PaymentMethods
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0, left: 0 }
  }
});

export default router;

//If you want to check Authorized status everytime a navigating action is trigger,
// just uncommnet router.beforeEach()

router.beforeEach((to, from, next) => {
  //on mobile (down lg) close menu on load section
  if (to.meta.application) {
    globalMixin.getBalance()
  }
  const authRequired = !!to.meta.authRequired;
  var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if(screenWidth<992){
    document.body.classList.remove('sb-sidenav-toggled');
  }
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});



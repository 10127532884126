<template>
  <div>
    <v-dialog v-model="dialogStatus" width="1000">
      <v-card class="pa-6">
        <v-card-title>Ingrese su email</v-card-title>
        <v-card-subtitle>Se le enviará un mail y un SMS con una clave de acceso para ingresar a su cuenta</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
              v-model="email"
              color="primary"
              label="Email"
              prepend-icon="mdi-email"
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="phone"
              color="primary"
              label="Teléfono"
              prepend-icon="mdi-phone"
              variant="outlined"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn prepend-icon="mdi-send" :loading="loading" :disabled="!formReady" color="primary" @click="resetPassword">Recibir clave de acceso</v-btn>
          <v-btn prepend-icon="mdi-close" color="red" @click="closeDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import userService from "@/services/user.service";

export default {
  name: "ForgottenPasswordModal",

  props: {
    status: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data () {
    return {
      dialogStatus: false,
      email: '',
      loading: false,
      phone: '',
    }
  },

  computed: {
    formReady () {
      return this.phone.length > 0 && this.email.length > 0
    },
  },

  watch: {
    status (value) {
      if (value) {
        this.dialogStatus = value
      }
    },
    dialogStatus (value) {
      if (!value) {
        this.$emit('update-status', value)
      }
    }
  },
  methods: {
    async resetPassword () {
      this.loading = true
      try {
        const data = {
          phone_number: this.phone,
          email: this.email
        }
        const res = await userService.rememberPassword(data, null)
        this.token = res.data[0]
      } catch (e) {
        console.log('Error reseteando contraseña', e)
      } finally {
        this.loading = false
        console.log(this.token)
        this.$router.push({
          name: 'reset-password',
          params: { token: this.token }
        });
      }
    },
    closeDialog() {
      this.dialogStatus = false
      this.email = ''
      this.phone = ''
    },
  }
}
</script>


<style scoped lang="scss">

</style>
<template>
  <div v-if="routeHasBreadcrumbsEnabled" class="app-breadcrumbs ma-4">
    <v-btn
      v-bind="props"
      size="small"
      variant="text"
    >
      <router-link class="text-decoration-none" to="/dashboard">Menú Principal</router-link>
    </v-btn>
    <v-icon v-if="sublinks.length" color="primary" icon="mdi-arrow-right-thin"/>
    <v-menu v-if="sublinks.length">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          append-icon="mdi-chevron-down"
          color="primary"
          size="small"
          variant="text"
        >
          {{ father.name }}
        </v-btn> 
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in sublinks"
          :key="i"
          :value="i"
        >
        <router-link class="text-decoration-none" :to="item.to">
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </router-link>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-icon color="primary" icon="mdi-arrow-right-thin"/>
    <v-btn 
      readonly
      size="small"
      variant="text"
    >
      {{ actual }}
    </v-btn>
  </div>
</template>

<script>
import breadcrumbs from '../../lib/breadcrumbs';

export default {
  name: 'app-breadcrumbs',
  data() {
    return {
      breadcrumbs,
      father: {},
      sublinks: []
    }
  },

  computed: {
    actual () {
      return this.$route.name
    },
    routeHasBreadcrumbsEnabled () {
      return !!this.$route.meta.breadcrumb
    }
  },

  created () {
    if (this.routeHasBreadcrumbsEnabled) this.locateBreadcrumb()
  },

  methods: {
    locateBreadcrumb () {
      this.father = this.breadcrumbs.find(elem => elem.id === this.$route.meta.breadcrumb)
      this.sublinks = this.father.sublinks.filter((elem) => {
        if (elem.to !== this.$route.path) return elem
      })
    }
  },
};
</script>
import baseService from "@/services/baseService";

class UserService {
  getPublicContent() {
    return baseService.get('all');
  }

  getUserBoard() {
    return baseService.get('user');
  }

  getModeratorBoard() {
    return baseService.get('mod');
  }

  getAdminBoard() {
    return baseService.get('admin');
  }

  rememberPassword(data, token = null) {
    if (!token) {
      return baseService.post('reset-password/', data)
    } else {
      return baseService.post(`reset-password/${token}/`, data)
    }
  }

  resetPasswordWhenLoggedIn(data) {
    return baseService.put('my-account/password/', data)
  }

  registerUser(data) {
    return baseService.post('register/', data)
  }

  invalidateToken() {
    return baseService.post('invalidate-token/', {})
  }

  registerToken(data) {
    return baseService.post('register-token/', data)
  }
}

export default new UserService();
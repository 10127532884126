import baseService from "./baseService";

class ContactImporterService {
   getImportCount () {
      return baseService.post('contacts/import/')
   }
   deleteLoad (data) {
      return baseService.post('contacts/import/delete/', { key: data })
   }
   listAllLoads () {
      return baseService.post('contacts/import/list/')
   }
}

export default new ContactImporterService()
<template>
  <v-footer class="starpage-footer d-flex justify-content-between pa-12" style="background-color: #1d2a3a">
    <v-row class="d-flex justify-content-around">
      <div class="col-12 col-md-4 text-center">
        <p class="text-h5 text-white">Empresa</p>
        <router-link class="text-decoration-none" to="terminos-condiciones">
          <p class="text-white font-weight-black">Términos y condiciones</p>
        </router-link>
        <router-link class="text-decoration-none" to="politica-privacidad">
          <p class="text-white font-weight-black">Política de privacidad</p>
        </router-link>
      </div>
      <div class="col-12 col-md-4 text-center">
        <p class="text-h5 text-white">Conecta con nosotros</p>
        <a class="text-decoration-none" href="tel: 911 095 388">
          <p class="text-white font-weight-black">
            <v-icon icon="mdi-phone"/>
            911 095 388
          </p>
        </a>
        <a class="text-decoration-none" href="mailto:smsempresa@xilon.com">
          <p class="text-white font-weight-black">
            <v-icon icon="mdi-email"/>
            smsempresa@xilon.com
          </p>
        </a>
        <div>
          <a class="me-2" href="https://www.instagram.com/es.smsempresa.es/">
            <v-btn variant="text" color="white" icon="mdi-instagram"></v-btn>
          </a>
          <a class="ms-2" href="https://www.facebook.com/SmsEmpresaes-103919974661721/">
            <v-btn variant="text" color="white" icon="mdi-facebook"></v-btn>
          </a>
        </div>
        <router-link v-if="loggedIn" to="/dashboard">
          <v-btn class="mt-3" color="white" variant="flat" rounded="lg">
            <span class="fw-bold">Accede a tu panel</span>
          </v-btn>
        </router-link>
        <router-link v-else to="/login">
          <v-btn class="mt-3" color="white" variant="flat" rounded="lg">
            <span class="fw-bold">Inicio sesión</span>
          </v-btn>
        </router-link>
      </div>
    </v-row>
  </v-footer>
</template>

<script>
import userService from "@/services/user.service";

export default {
  name: "startpageFooter",
  data () {
    return {
      email: '',
      phone: '',
      privacyPoliticReaded: false
    }
  },

  computed: {
    isFormCompleted () {
      return !(this.email?.length > 0 && this.phone?.length > 0 && this.privacyPoliticReaded)
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    async registerUser () {
      try {
        const data = {
          email: this.email,
          phone_number: this.phone
        }
        const res = await userService.registerUser(data)
        console.log(res)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
import baseService from "@/services/baseService";
//mia

//fin mia


class AuthService {


  login(user) {
    return baseService
      .post('login-check', {
        username: user.username,
        password: user.password
      })
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return baseService.post('register/', {
      email: user.email,
      phone_number: user.phone_number
    })
  }

}

export default new AuthService();

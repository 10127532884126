<template>
  <div class="app-toast">
    <v-snackbar v-model="snackbarStatus" multi-line color="primary" timeout="2000" timer="success" @update:modelValue="detectClose">
      <div class="d-flex align-items-center">
        <v-icon
            class="me-3"
            size="x-large"
            :icon="!isToastError ? 'mdi-check-circle' : 'mdi-alert-circle'"
            :color="!isToastError ? 'success' : 'danger'"
        />
        <span>{{ message }}</span>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "AppToast",

  data () {
    return {
      isToastError: false,
      message: '',
      snackbarStatus: false
    }
  },

  watch: {
    '$store.state.toast.display'() {
      if (this.$store.state.toast.error) {
        this.snackbarStatus = this.$store.state.toast.display
        this.message = this.$store.state.toast.message
        this.isToastError = this.$store.state.toast.error
      } else {
        this.snackbarStatus = this.$store.state.toast.display
        this.message = this.$store.state.toast.message
        this.isToastError = false
      }
    },
    '$store.state.toast.error'() {
      this.snackbarStatus = this.$store.state.toast.display
      this.message = this.$store.state.toast.message
      this.isToastError = this.$store.state.toast.error
    }
  },

  methods: {
    detectClose (status) {
      this.$store.state.toast.display = status
      this.$store.state.toast.error = false
    }
  }
}
</script>


<style scoped lang="scss">

</style>
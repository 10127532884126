<template>
  <router-link to="/account-order">
    <v-btn v-if="!isMobileSize" color="primary" rounded prepend-icon="mdi-currency-usd">
      <span class="d-none d-sm-block">Obtener saldo</span>
    </v-btn>
    <v-btn v-else variant="text" color="primary" icon="mdi-currency-usd"/>
  </router-link>
</template>

<script>
export default {
  name: "GetCashButton",

  computed: {
    isMobileSize () {
      return this.$vuetify.display.smAndDown
    }
  }
}
</script>
<template>
 <div class="confirmation-modal">
   <v-dialog v-model="dialogStatus" width="350">
      <v-card class="pa-4">
         <v-card-title class="text-center">¿Estás segur@?</v-card-title>
         <v-card-actions>
            <v-btn icon="mdi-check" color="green" variant="outlined" size="small"/>
            <v-btn icon="mdi-close" color="red" variant="outlined" size="small" @click="closeDialog"/>
         </v-card-actions>   
      </v-card>
   </v-dialog>
 </div>
</template>

<script>
export default {
   name: 'confirmation-modal',

   data () {
      return {
         dialogStatus: false
      }
   },

   watch: {
      '$store.state.modal.display' () {
         if (this.$store.state.modal.display) {
            this.dialogStatus = true
         } else {
            this.dialogStatus = false
         }
      },
      dialogStatus () {
         if (!this.dialogStatus) {
            this.$store.state.modal.display = false
         }
      }
   },

   methods: {
      closeDialog () {
         this.$store.state.modal.display = false
      }
   }
}
</script>